import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import fileDownload from "js-file-download";

import { getContract } from "../services/ContractService";
import CenteredStack from "../components/CenteredStack";

export default function DownloadContractPage() {
  const { contractId, signatoryId } = useParams<{
    contractId: string;
    signatoryId: string;
  }>();
  const [processing, setProcessing] = useState(true);
  const [fileSource, setFileSource] = useState<Blob | null | undefined>();

  useEffect(() => {
    if (contractId && signatoryId) {
      setProcessing(true);
      getContract(contractId, signatoryId)
        .then((res) => {
          setFileSource(new Blob([res.data], { type: "application/pdf" }));
        })
        .catch((e) => {
          console.log(" FAILED ", e);
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [contractId, signatoryId]);

  const handleDownload = () => {
    if (fileSource) {
      fileDownload(fileSource, "contrat.pdf");
    }
  };

  return (
    <Stack
      sx={{ flex: 1, height: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      {processing ? (
        <CenteredStack>
          <CircularProgress />
          <Typography variant="body1" color="white">
            Préparation de vos documents
          </Typography>
        </CenteredStack>
      ) : fileSource ? (
        <CenteredStack sx={{ color: "#FFF" }}>
          <DownloadIcon fontSize="large" color="inherit" />
          <Typography variant="body1" color="white">
            Vos documents sont prêts !
          </Typography>
          <Button variant="contained" onClick={handleDownload}>
            Télécharger
          </Button>
        </CenteredStack>
      ) : (
        <Typography variant="body1" color="white">
          Une erreur est survenue lors de la préparation du contrat
        </Typography>
      )}
    </Stack>
  );
}
