const ENV_LOCAL = {
  FRONT: "http://localhost:3001",
  API: "http://localhost:8000",
};

const ENV_PPD = {
  FRONT: "https://preprod.contrats.kc.plateforms.tech/",
  API: "https://preprod.api.kc.plateforms.tech",
};

const ENV_PROD = {
  FRONT: "https://kc.contrats.plateforms.tech/",
  API: "https://api.kc.plateforms.tech",
};

const config =
  process.env.REACT_APP_ENV === "PREPROD"
    ? ENV_PPD
    : process.env.REACT_APP_ENV === "PROD"
    ? ENV_PROD
    : ENV_LOCAL;

export default config;
