import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultPage from "./pages/DefaultPage";
import DownloadContractPage from "./pages/DownloadContractPage";
import DownloadContractWithSecurityPage from "./pages/DownloadContractWithSecurityPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/contrats/:contractId/:signatoryId"
          element={<DownloadContractPage />}
        />
        <Route
          path="/corpo/:contractId"
          element={<DownloadContractWithSecurityPage />}
        />
        <Route path="*" element={<DefaultPage />} />
      </Routes>
    </BrowserRouter>
  );
}
