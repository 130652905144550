import axios from "axios";
import config from "../config";

export const getContract = (contractId: string, signatoryId: string) =>
  axios.get(`${config.API}/contracts/${contractId}/${signatoryId}`, {
    responseType: "blob",
  });

export const getSecuredContract = (contractId: string, password: string) =>
  axios.post(
    `${config.API}/contracts/${contractId}/securedDownload`,
    { password },
    { responseType: "blob" }
  );
