import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";

declare type Props = {
  children: JSX.Element | JSX.Element[];
} & BoxProps;

export default function CenteredStack({ children, sx, ...rest }: Props) {
  return (
    <Box
      sx={{
        p: 2,
        maxWidth: 400,
        width: { xs: "80%", sm: "70%", md: "50%" },
        height: 200,
        ...sx,
      }}
      {...rest}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={3}
        sx={{ height: "100%" }}
      >
        {children}
      </Stack>
    </Box>
  );
}
