import * as React from "react";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import fileDownload from "js-file-download";

import { getSecuredContract } from "../services/ContractService";

export default function DownloadContractWithSecurityPage() {
  const { contractId } = useParams<{ contractId: string }>();
  const [securityCode, setSecurityCode] = React.useState("");
  const [processing, setProcessing] = React.useState(false);
  const [securityCodeError, setSecurityCodeError] = React.useState<
    string | undefined
  >();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecurityCodeError(undefined);
    setSecurityCode(e.target.value.toUpperCase());
  };

  const handleSubmit = () => {
    if (securityCode && contractId) {
      setProcessing(true);
      getSecuredContract(contractId, securityCode)
        .then((res) => {
          const fileSource = new Blob([res.data], { type: "application/pdf" });
          fileDownload(fileSource, "contrat.pdf");
        })
        .catch((e) => {
          switch (e?.response?.status) {
            case 403:
              setSecurityCodeError("Code incorrect");
              break;
            case 404:
              setSecurityCodeError("Contrat introuvable");
              break;
            case 410:
              setSecurityCodeError(
                "Le contrat a été contresigné et ne peut plus être téléchargé depuis ce lien"
              );
              break;
            default:
              setSecurityCodeError("Une erreur est survenue");
              break;
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  };

  return (
    <Stack
      sx={{ flex: 1, height: "100%" }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack spacing={3}>
        <Typography variant="body1" color="white">
          Veuillez saisir le code qui vous a été transmis :
        </Typography>
        <TextField
          autoFocus
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) => theme.palette.primary.main,
              borderWidth: 2,
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: (theme) =>
                securityCodeError
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
              borderWidth: 2,
            },
          }}
          inputProps={{
            style: { color: "#FFF", textAlign: "center" },
          }}
          variant="outlined"
          value={securityCode}
          onChange={handleInputChange}
          placeholder="CODE"
          error={!!securityCodeError}
          helperText={!!securityCodeError && securityCodeError}
          required
        />
        <LoadingButton
          sx={{ marginTop: 24 }}
          disabled={!securityCode}
          variant="contained"
          onClick={handleSubmit}
          loading={processing}
          loadingIndicator={<CircularProgress color="primary" size={24} />}
        >
          Télécharger
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
