import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function NoContentHere() {
  return (
    <Stack
      id="notfound"
      sx={{ backgroundColor: "black", width: "100%", height: "100%" }}
      alignItems="center"
      justifyContent="center"
      spacing={3}
    >
      <Typography variant="h3" color="primary" component="p">
        Page non trouvée
      </Typography>
      <Typography
        variant="h2"
        component="p"
        color="secondary"
        sx={{ fontWeight: "bold" }}
      >
        404
      </Typography>
    </Stack>
  );
}
